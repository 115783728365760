<template>
  <div class="form__fileinput">
    <a href="" v-if="value != ''" class="form__fileinput-delete" @click.prevent="clearInput" title="Удалить"></a>
    <label :for="inputName">
      <input type="file" :name="inputName" :id="inputName" ref="fileInput" @change="getInputValue">
      <span>
        <template v-if="value === ''">Прикрепить файл</template>
        <template v-else>{{ value }}</template>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  props: ['inputName'],
  data() {
    return {
      value: '',
    }
  },
  methods: {
    getInputValue() {
      this.value = this.$refs.fileInput.value.replace(/C:\\fakepath\\/i, '');
    },
    clearInput() {
      this.value = '';
      this.$refs.fileInput.value = '';
    }
  },
}
</script>

