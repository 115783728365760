<template>
  <div v-if="isFormVisible" class="description__form-wrapper">
    <div class="description__form popup__box">
      <span class="popup__close description__form-close" @click="hideForm"></span>
      <form v-if="!formSubmitted" @submit.prevent="submitForm">
        <h2 class="description__form-title">
          Пожалуйста, расскажите нам о себе!
        </h2>
        <div class="description__form-checkboxes">
          <label v-for="(checkbox, index) in questions[0].answers" :key="index" class="description__form-label">
            <input type="radio" v-model="selectedCheckbox" :value="checkbox.id" class="description__form-input" />
            <span class="custom-checkbox"></span>
            <span class="custom-checkbox-text">{{ checkbox.value }}</span>
          </label>
        </div>
        <button
          class="btn btn_pink content__animation-3 description__form-btn"
          type="submit"
          :disabled="!canSubmitForm"
        >
          Отправить
        </button>
      </form>
      <p class="description__form-text" v-else-if="errorText.length > 0">
        {{ errorText }}
      </p>
      <p class="description__form-text" v-else>
        Спасибо за ваш ответ!
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    questions: Array,
  },
  data() {
    return {
      isFormVisible: false,
      formSubmitted: false,
      selectedCheckbox: null,
      errorText: '',
    };
  },
  methods: {
    hideForm() {
      this.isFormVisible = false;
    },
    // Метод для отправки результатов опроса на сервер
    async submitForm() {
      if (!this.formSubmitted && this.canSubmitForm) {
        const selectedActivity = this.selectedCheckbox;

        const data = {
          actionMethod: 'addVoteResult',
          voteCode: 'tell_us_about_yourself',
          activities: selectedActivity,
        };

        const url = `/local/ajax/vote.php?actionMethod=${data.actionMethod}&voteCode=${data.voteCode}&activities=${data.activities}`;

        try {
          const response = await axios.post(url);
          this.formSubmitted = true;

          if (response.data.status === 'ok') {
            localStorage.setItem('formShown', 'true');
          } else {
            this.errorText = response.data.error;
          }
        } catch (error) {
          this.errorText = error;
        }
      }
    },
  },
  computed: {
    canSubmitForm() {
      return this.selectedCheckbox !== null;
    }
  },
  created() {
    const formShown = localStorage.getItem('formShown');
    if (formShown) {
      this.isFormVisible = false;
    } else {
      setTimeout(() => {
        this.isFormVisible = true;
      }, 8000);
    }
  },
};
</script>
