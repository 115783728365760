<template>
  <div class="compare-table"
       :class="{'compare-table_closed': closed}"
       @mouseover="open = true"
       @mouseleave="closeTable"
  >
    <div class="compare-table__table-container">
      <table class="compare-table__table">
        <tr>
          <th>
            <h3 class="compare-table__item-name">Сравнение</h3>
            <p class="compare-table__item-descr">Систем по параметрам и преимуществам</p>
          </th>
          <th v-for="(system, index) in systems" :key="index" :class="{'_selected': system.id === active}">
            <a :href="system.link">
              <h3 class="compare-table__item-name">{{ system.name }}</h3>
              <p class="compare-table__item-descr">{{ system.descr }}</p>
            </a>
          </th>
        </tr>
      </table>
      <VueSlideToggle :open="open" tag="div" :duration="500"  class="compare-table__table-wrap">
        <table class="compare-table__table">
          <tr v-for="(feature, index) in features" :key="index">
            <td><span class="compare-table__feature">{{ feature.text }}</span></td>
            <td v-for="(system, index) in systems" :key="index" :class="{'_selected': system.id === active}">
              <rating :size="system[feature.name]"></rating>
            </td>
          </tr>
          <tr>
            <td>
              <div
                class="compare-table__feature compare-table__feature_with-tip"
                @mouseover="tipVisible = true"
                @mouseleave="tipVisible = false"
              >
                Средняя цена <span class="_g-nowrap">за 1 м2</span><sup>*</sup>
                <transition name="fade">
                  <div class="compare-table__feature-tip" v-if="tipVisible">
                    *С учетом комплектующих
                    материалов и утеплителя
                  </div>
                </transition>
              </div>
            </td>
            <td v-for="(system, index) in systems" :key="index" :class="{'_selected': system.id === active}">
              <a :href="system.link"><span class="compare-table__price">{{ system.price }} ₽</span></a>
            </td>
          </tr>
        </table>
      </VueSlideToggle>
    </div>
    <table v-if="closed" class="compare-table__table compare-table__table_arrow-space">
      <tr>
        <td></td>
        <td v-for="(system, index) in systems" :key="index" :class="{'_selected': system.id === active}">
          <span v-if="system.id === active" class="compare-table__current-arrow">
            <img src="/frontend/img/decor/arrow-light.png" alt="">
            <img src="/frontend/img/decor/arrow.png" alt="">
          </span>
        </td>
      </tr>
    </table>
    <div class="compare-table__slider">
      <slider-features class="slider slider_features" :init-slide="active">
        <swiper-slide v-for="(system, index) in systems" :key="index">
          <div class="compare-table__slide" :class="{'_selected': system.id === active}">
            <h3 class="compare-table__item-name">{{ system.name }}</h3>
            <p class="compare-table__item-descr">{{ system.descr }}</p>
            <a :href="`${system.link}`" class="arrow-link compare-table__link">Подробнее о системе</a>
            <div class="compare-table__feature">
              {{ features[0].text}}
              <rating :size="system.vapor"></rating>
            </div>
            <div class="compare-table__feature">
              {{ features[1].text}}
              <rating :size="system.selfclean"></rating>
            </div>
            <div class="compare-table__feature">
              {{ features[2].text}}
              <rating :size="system.installation"></rating>
            </div>
            <div class="compare-table__feature">
              {{ features[3].text}}
              <rating :size="system.longevity"></rating>
            </div>
            <div class="compare-table__feature compare-table__feature_with-tip">
              <span>Средняя цена <span class="_g-nowrap">за 1 м2</span><sup>*</sup></span>
              <span class="compare-table__price">{{ system.price }} ₽</span>
              <div class="compare-table__feature-tip">
                *С учетом комплектующих
                материалов и утеплителя
              </div>
              <span v-if="system.id === active" class="compare-table__current-arrow">
                <svg width="280" height="28" viewBox="0 0 280 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M280 0H0L138.828 28L280 0Z" fill="#F0F2F7"/>
                </svg>
              </span>
            </div>
          </div>
        </swiper-slide>
      </slider-features>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompareTable',
  props: {
    active: {
      type: Number,
      default: 0,
    },
    closed: {
      type: Boolean,
      default: false,
    },
    initFeatures: {
      type: Array,
      default: () => [
        {
          name: 'vapor',
          text: 'Паропроницаемость',
        },
        {
          name: 'selfclean',
          text: 'Самоочищение',
        },
        {
          name: 'installation',
          text: 'Скорость монтажа',
        },
        {
          name: 'longevity',
          text: 'Долговечность',
        },
      ]
    },
    initSystems: {
      type: Array,
      default: () => [
        {
          id: 1,
          name: 'Popular',
          descr: 'Экономичное решение для утепления загородного дома',
          vapor: 2,
          selfclean: 2,
          installation: 2,
          longevity: 2,
          price: 950,
          link: 'system.php',
        },
        {
          id: 2,
          name: 'Express',
          descr: 'Самая быстро монтируемая и удобная система',
          vapor: 2,
          selfclean: 2,
          installation: 4,
          longevity: 3,
          price: 1110,
          link: 'system.php',
        },
        {
          id: 3,
          name: 'Aquastatic',
          descr: 'Система утепления с повышенной защитой от влаги и загрязнения',
          vapor: 3,
          selfclean: 4,
          installation: 4,
          longevity: 4,
          price: 1270,
          link: 'system.php',
        },
        {
          id: 4,
          name: 'Self clean',
          descr: 'Самая долговечная система утепления с эффектом самоочищения',
          vapor: 3,
          selfclean: 5,
          installation: 4,
          longevity: 5,
          price: 1580,
          link: 'system2.php',
        },
      ]
    }
  },
  data() {
    return {
      features: this.initFeatures,
      systems: this.initSystems,
      open: !this.closed,
      tipVisible: false,
    }
  },
  methods: {
    closeTable() {
      if (this.closed) {
        this.open = false;
      }
    },
  }
}
</script>
