<template>
  <div>
    <div
      class="docs__item-link"
      v-for="(field, index) in initFileFields"
      :key="index"
      @click="showTip" v-click-outside="hideTip"
      v-show="item[field.CODE] && (showField === field.CODE || showField === '')"
    >
      {{ field.SHORT }}
      <a :href="item[field.CODE]" class="docs__item-link-tip">
        {{ field.TITLE }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocLinks',
  props: {
    initFileFields: { type: Array, required: true },
    item: { type: Object, required: true },
    showField: { type: String, default: '' },
  },
  methods: {
    showTip(e) {
      e.target.classList.add('docs__item-link_active');
    },
    hideTip(e, el) {
      el.classList.remove('docs__item-link_active');
    },
  }
}
</script>
