<template>
  <div>
    <swiper v-if="!multiple" ref="mySwiper" :options="swiperOptions1">
      <slot />
      <div v-if="!multiple" class="slider__pagination" slot="pagination"></div>
      <div class="slider__nav-button slider__nav-button_prev" slot="button-prev">prev</div>
      <div class="slider__nav-button slider__nav-button_next" slot="button-next">next</div>
    </swiper>
    <swiper v-else ref="mySwiper" :options="swiperOptions2">
      <slot />
      <div class="slider__nav-button slider__nav-button_prev" slot="button-prev">prev</div>
      <div class="slider__nav-button slider__nav-button_next" slot="button-next">next</div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiperOptions1: {
        spaceBetween: 20,
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
          el: '.slider__pagination',
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            return (current < 10 ? '0' + current : current) + '/' + (total < 10 ? '0' + total : total);
          }
        },
        navigation: {
          nextEl: '.slider__nav-button_next',
          prevEl: '.slider__nav-button_prev',
          disabledClass: 'slider__nav-button_disabled',
        },
        breakpoints: {
          768: {
            spaceBetween: 50,
          },
          1024: {
            spaceBetween: 120,
          }
        }
      },
      swiperOptions2: {
        spaceBetween: 30,
        slidesPerView: 1,
        autoHeight: true,
        navigation: {
          nextEl: '.slider__nav-button_next',
          prevEl: '.slider__nav-button_prev',
          disabledClass: 'slider__nav-button_disabled',
        },
        breakpoints: {
          678: {
            slidesPerView: 1,
            spaceBetween: 35,
          },
          1024: {
            slidesPerView: 2,
          },
        }
      }
    }
  },
}
</script>

