<template>
  <div>
    <swiper ref="mySwiper" :options="swiperOptions">
      <slot />
      <div class="slider__nav-button slider__nav-button_prev" slot="button-prev">prev</div>
      <div class="slider__nav-button slider__nav-button_next" slot="button-next">next</div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: 'SliderFeatures',
  props: {
    initSlide: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        slidesPerView: 1,
        autoHeight: true,
        initialSlide: this.initSlide - 1,
        navigation: {
          nextEl: '.slider__nav-button_next',
          prevEl: '.slider__nav-button_prev',
          disabledClass: 'slider__nav-button_disabled',
        },
        breakpoints: {
          678: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        }
      }
    }
  },
}
</script>

