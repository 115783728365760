<template>
  <div>
    <div class="filter filter_small-margin">
      <div class="filter__list">
        <a
          href=""
          class="filter__item"
          :class="{ 'filter__item_active': item.code === filterCode }"
          v-for="(item, index) in filterList"
          :key="index"
          v-html="item.title"
          @click.prevent="setFilterValue(item.code)"
        />
      </div>
      <div class="filter__select">
        <select @change="setFilterValue($event.target.code)">
          <option
            v-for="(item, index) in filterList"
            :key="index"
            v-html="item.title"
            :value="item.code"
            :selected="item.code === filterCode"
          />
        </select>
      </div>
    </div>
    <div class="docs">
      <div v-for="(section, index) in filteredSections" :key="index">
        <div class="docs__row">
          <h3 class="docs__group-title">{{ section.NAME }}</h3>
        </div>
        <div class="docs__row" v-for="(item, index2) in section.ITEMS" :key="index2">
          <div class="docs__item">
            <h3>{{ item.NAME }}</h3>
            <doc-links :item="item" :show-field="filterCode" :init-file-fields="initFileFields"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocsTable",
  props: {
    initFileFields: { type: Array, required: true },
    initSections: { type: Array, required: true },
  },
  data() {
    return {
      filterCode: '',
      sections: this.initSections,
    };
  },
  computed: {
    filterList() {
      return [
        { code: '', title: 'Все'},
        ...this.initFileFields.map(item => {
          return {
            code: item.CODE,
            title: item.TITLE,
            html: item.HTML,
          }
        })
      ];
    },
    filteredSections() {
      if (this.filterCode !== '') {
        const newSections = [];
        this.sections.forEach(section => {
          const newSection = {
            NAME: section.NAME,
            ITEMS: [],
          };
          section.ITEMS.forEach(item => {
            if (item[this.filterCode]) {
              newSection.ITEMS.push(item);
            }
          });
          if (newSection.ITEMS.length) {
            newSections.push(newSection);
          }
        });
        return newSections;
      } else {
        return this.sections;
      }
    },
  },
  methods: {
    setFilterValue(value) {
      this.filterCode = value;
    }
  }
}
</script>
