<template>
  <div class="popup" v-if="visible" :class="{'popup_long': long}">
    <div class="popup__box" v-click-outside="outsideClose">
      <span class="popup__close" @click="close"></span>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'popup',
  props: {
    id: { type: String, required: true },
    long: {type: Boolean, required: false}
  },
  computed: {
    visible() {
      return this.$root.currentPopup === this.id;
    },
  },
  methods: {
    outsideClose(e) {
      if (e.target === this.$el) {
        this.close();
      }
    },
    close() {
      this.$root.currentPopup = null;
    },
  },
}
</script>
