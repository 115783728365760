<template>
  <div class="compare-table__rating">
    <span v-for="i in size" :key="i">{{ i }}</span>
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    size: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {}
  },
}
</script>
