<template>
  <div class="tabs">
    <div class="tabs__tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['tabs__tab', {'tabs__tab_active': tab.active}]"
        @click="select(index)"
        v-html="getTabLabel(tab)"
      >
      </div>
    </div>
    <div class="tabs__content">
      <slot/>
    </div>
  </div>
</template>

<script>
import getVNodeHTML from '../utils/vnode2html';

export default {
  name: 'Tabs',
  props: {
  },
  data() {
    return {
      tabs: this.$children,
    };
  },
  mounted() {
  },
  methods: {
    getTabLabel(tab) {
      const { $slots: { label: [vnode] = [] } = {}, label } = tab;
      return vnode ? getVNodeHTML(vnode) : label;
    },
    select(id) {
      if (this.tabs[id].noChanged) return;
      this.tabs.forEach((el, i) => {
        el.active = i === id;
      });
    },
  }
}
</script>
